/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { findBySlug } from "../helpers"
import Hero from "../components/hero"
import Page from "../components/page"
import BenefitsModule from "../components/benefits-module"
import { paramsCheck } from "../functions/functions"
import queryString from "query-string"

const ConsumersPage = ({ data, location }) => {
  paramsCheck()

  let categoryQuery = queryString.parse(location.search).category
  if (location.search.includes("&")) {
    let decodeURL = decodeURIComponent(location.search)
      .split("/")
      .find(ele => ele.includes(categoryQuery))
      .split("=")
      .find(ele => ele.includes(categoryQuery))
    categoryQuery = decodeURL
  }

  let filterData

  if (categoryQuery) {
    filterData = data.news.nodes
      .filter(ele => ele.category)
      .filter(ele => ele.category === categoryQuery)
  } else {
    categoryQuery = "latest"
  }

  const renderHeroBladePattern = () => {
    const heroBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "consumers-center-hero-module",
    })
    const heroHeights = [200, 300]

    return (
      <Hero
        customStyle={{
          minHeight: heroHeights,
          height: heroHeights,
        }}
        background={{
          type: "image",
          source: {
            mobileImage: heroBladePatternData.mobileImage,
            desktopImage: heroBladePatternData.desktopImage,
          },
        }}
        title={heroBladePatternData.title}
      ></Hero>
    )
  }

  const renderBenefitsModule = () => {
    const benefitsModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "consumers-benefits-module",
    })

    if (!benefitsModuleBladePatternData) return

    return (
      <BenefitsModule
      bladePatternData={benefitsModuleBladePatternData}
      
    />
    )
  }

  return (
    <Page data={data.contentfulPage}>
      {renderHeroBladePattern()}
      {renderBenefitsModule()}
    </Page>
  )
}

export default ConsumersPage

export const query = graphql`
  query consumersPageQuery {
    contentfulPage(slug: { eq: "consumers" }) {
      ...PageData
      ...BladePatternData
    }
    news: allContentfulNews(filter: { type: { eq: "consumers" } }) {
      nodes {
        title
        teaser {
          teaser
        }
        subtitle {
          subtitle
        }
        link
        slug
        image {
          file {
            url
          }
          description
        }
        category
        author
        updatedAt
        publicationTitle
        date
      }
    }
  }
`
